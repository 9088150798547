import '../scss/james-search.scss';

import Mustache from '../libs/mustache.custom';
import Utils from '../libs/utils';

/**
 * @class JamesSearch
 * @param {String@DOMElement} el
 * @param {Object} options
 * @return {JamesSearch} New instance of JamesSearch.
 * @this JamesSearch
 */
var JamesSearch = function (options) {
    JamesSearch.instances.push(this);
    JamesSearch.prototype.initialize.call(this, options);
};

// Constructor.
JamesSearch.prototype.constructor = JamesSearch;

/**
 * Hold all JamesSearch instances.
 * @static
 * @type {Array}
 */
JamesSearch.instances = [];

/**
 * Create one or more JamesSearch instances if a global object|array jamesSearchOptions is defined.
 * @static
 */
JamesSearch.autoCreate = function() {
    var i = 0;

    Utils.ready(() => {
        if ('jamesSearchOptions' in window) {
            if (Array.isArray(window.jamesSearchOptions)) {
                for (i = 0; i < window.jamesSearchOptions.length; i++) {
                    new JamesSearch(window.jamesSearchOptions[i]);
                }
            } else {
                new JamesSearch(window.jamesSearchOptions);
            }
        }
    });
};

/**
 * Destroy all JamesSearch instances.
 * @static
 */
JamesSearch.destroyAll = function() {
    if (JamesSearch.instances) {
        Array.prototype.forEach.call(JamesSearch.instances, (instance) => {
            JamesSearch.destroyInstance(instance);
        });
    }
};

/**
 * Destroy a specific JamesSearch instance.
 * @static
 * @param  {JamesSearch} instance A JamesSearch instance.
 */
JamesSearch.destroyInstance = function(instance) {
    var index = JamesSearch.instances.indexOf(instance);
    if (index !== -1) {
        JamesSearch.instances.splice(index, 1);
    }

    if (instance.constructor === JamesSearch) {
        instance.destroy();
    }
};

/**
 * Define the error messages.
 * @static
 * @enum {String}
 */
JamesSearch.ERROR = {
    noDomObject: 'JamesSearch error: DOM Object not found',
    templateNotFound: 'JamesSearch error: Template not found',
    formNotFound: 'JamesSearch error: Form Element not found',
    inputNotFound: 'JamesSearch error: Input text Element not found',
};

/**
 * Define the type of events.
 * @static
 * @enum {String}
 */
JamesSearch.EVENTS = {
    ready: 'james-search-ready',

    // Request events
    beforeSend: 'james-search-before-send',
    success: 'james-search-success',
    error: 'james-search-error',
    complete: 'james-search-complete',

    suggestTrackingBeforeSend: 'james-search-suggest-tracking-before-send',
    searchTrackingBeforeSend: 'james-search-search-tracking-before-send',

    // UI changes events
    boxBeforeAdd: 'james-search-box-before-add',
    boxAdded: 'james-search-box-added',
    boxBeforeUpdate: 'james-search-box-before-update',
    boxUpdated: 'james-search-box-updated',
    boxShown: 'james-search-box-shown',
    boxEnter: 'james-search-box-enter',
    boxLeave: 'james-search-box-leave',
    boxHidden: 'james-search-box-hidden',
    boxRemoved: 'james-search-box-removed',
    boxBeforeResize: 'james-search-box-before-resize',
    boxResized: 'james-search-box-resized',

    // User interaction events
    inputFocus: 'james-search-input-focus',
    inputBlur: 'james-search-input-blur',
    inputEnter: 'james-search-input-enter',
    inputLeave: 'james-search-input-leave',
    inputChange: 'james-search-input-change',
};

/**
 * Triggered on ready.
 * @event JamesSearch#ready
 */

/**
 * Triggered before an ajax request is sent.
 * @event JamesSearch#before-send
 * @property {Mixed} data - The data to be sent.
 */

/**
 * Triggered on ajax request success.
 * @event JamesSearch#success
 * @property {Object} data - The data received.
 */

/**
 * Triggered on ajax request error.
 * @event JamesSearch#error
 * @property {XMLHttpRequest} xhr
 */

/**
 * Triggered on ajax request completed.
 * @event JamesSearch#complete
 * @property {XMLHttpRequest} xhr
 */

/**
 * Triggered before a suggest tracking ajax request is sent.
 * @event JamesSearch#suggest-tracking-before-send
 * @property {Mixed} data - The data to be sent.
 */

/**
 * Triggered before a search tracking ajax request is sent.
 * @event JamesSearch#search-tracking-before-send
 * @property {Mixed} data - The data to be sent.
 */

/**
 * Triggered before the autosuggest box has been added.
 * @event JamesSearch#box-before-add
 * @property {String} html - The box html.
 */

/**
 * Triggered after the autosuggest box has been added.
 * @event JamesSearch#box-added
 * @property {String|DOMElement} html - The box element.
 */

/**
 * Triggered before the autosuggest box has been updated.
 * @event JamesSearch#box-before-update
 * @property {String} html - The box html.
 */

/**
 * Triggered after the autosuggest box has been updated.
 * @event JamesSearch#box-updated
 * @property {String|DOMElement} html - The box element.
 */

/**
 * Triggered after the autosuggest box has been showed.
 * @event JamesSearch#box-shown
 * @property {String|DOMElement} html - The box element.
 */

/**
 * Triggered on entering the autosuggest box.
 * @event JamesSearch#box-enter
 */

/**
 * Triggered on leaving the autosuggest box.
 * @event JamesSearch#box-leave
 */

/**
 * Triggered after the autosuggest box has been hidden.
 * @event JamesSearch#box-hidden
 */

/**
 * Triggered after the autosuggest box has been removed.
 * @event JamesSearch#box-removed
 */

/**
 * Triggered after a window resize event and before new styles be applied to the autosuggest box.
 * @event JamesSearch#box-before-resize
 * @property {Object} style - Object with styles to be applied to the autosuggest box.
*/

/**
 * Triggered after a window resize event and after new styles has been applied to the autosuggest box.
 * @event JamesSearch#box-resized
 * @property {CSSStyleDeclaration} style - The style object of the autosuggest box.
 */

/**
 * Triggered on focusing the autosuggest input.
 * @event JamesSearch#input-focus
 * @property {DOMElement} el - The target input element.
 */

/**
 * Triggered on blurring the autosuggest input.
 * @event JamesSearch#input-blur
 * @property {DOMElement} el - The target input element.
 */

/**
 * Triggered on entering the autosuggest input.
 * @event JamesSearch#input-enter
 * @property {DOMElement} el - The target input element.
 */

/**
 * Triggered on leaving the autosuggest input.
 * @event JamesSearch#input-leave
 */

/**
 * Triggered on input change
 * @event JamesSearch#input-change
 * @property {Event} originalEvent - The original event from input element.
 */

/**
 * @namespace
 * @property {Object} defaults - Default options.
 * @property {Boolean} defaults.debug - Disable the remove box handlers of mouseleave and blur events for debugging/sytling; preventDefault when click on tracking links. Default: 'false'.
 *
 * @property {Object} defaults.suggest - Default suggest options.
 * @property {String|DOMElement} defaults.suggest.el - The el id or the element itsself.
 * @property {String|Array} defaults.suggest.token - Current token from data service.
 * @property {String} defaults.suggest.url - Target URL.
 * @property {String} defaults.suggest.trackingUrl - Target tracking URL.
 * @property {String} defaults.suggest.method - Method used in the request. Default: 'GET'.
 * @property {Array|Number} defaults.suggest.limit - Max elements showed. Default: '10'.
 * @property {Array|Number} defaults.suggest.offset - Page showed. Default: '0'.
 * @property {Array|Number} defaults.suggest.crop - Crop the text. Default: '250'.
 * @property {Number} defaults.suggest.minChars - Minimum length char for queries. Default: '3'.
 * @property {Number} defaults.suggest.delay - Delay of the trigger in milliseconds (ms). Default: '100'.
 * @property {String} defaults.suggest.position - Position of the box. Values: 'left' | 'right'. Default: 'left'.
 * @property {String} defaults.suggest.template - Template used to render the box or ID of the script tag where the template is contained.
 * @property {String} defaults.suggest.target - Target used for rendering the Template.
 * @property {String} defaults.suggest.wrapperTemplate - Template used to render the box or ID of the script tag where the template is contained. Default: '&lt;div class="james-search-box"&gt;&lt;/div&gt;'.
 * @property {Boolean} defaults.suggest.keyboard - Give support to keyboard commands (escape, arrow up & arrow down) Default: 'true'.
 * @property {Boolean} defaults.suggest.horizontalKeyboard - Give support to keyboard commands (arrow right, arrow left). Useful when using columns. Default: 'false'.
 * @property {Number} defaults.suggest.margin - Margin to the border of the screen. Default: '15'.
 * @property {String} defaults.suggest.autoWidth - Set the default width rendering. Values: 'auto'|'fixed'. Default: 'auto'.
 * @property {Array} defaults.suggest.delimiters - Change the default delimiter tags of Mustache.js. Default: 'null'.
 * @property {Boolean} defaults.suggest.blurRemove - Remove the box from DOM when the focus is out. Default: 'false'.
 * @property {Boolean} defaults.suggest.inputDebounceDelay - Determines the amount of time (ms) that must pass until the debounced function is called again when changing the input. Default: '300'.
 *
 * @property {Object} defaults.search - Default search options.
 * @property {String} defaults.search.query - The query send to JamesSearch.
 * @property {String} defaults.search.selector - The selector of the link to search click URL.
 * @property {String} defaults.search.trackingUrl - Target tracking URL.
 * @property {String} defaults.search.method - Method used in the request. Default: 'GET'.
 */
JamesSearch.defaults = {
    debug: false,
    suggest: {
        el: null,
        token: [],
        url: '',
        trackingUrl: '',
        method: 'GET',
        limit: 10,
        offset: 0,
        crop: 250,
        minChars: 3,
        delay: 100,
        position: 'left',
        template: '',
        target: null,
        wrapperTemplate: '<div class="james-search-box"></div>',
        keyboard: true,
        horizontalKeyboard: false,
        margin: 15,
        autoWidth: 'auto',
        delimiters: null,
        blurRemove: false,
        inputDebounceDelay: 300,
    },
    search: {
        query: '',
        selector: '',
        trackingUrl: '',
        method: 'GET'
    },
};

/**
 * The function called after a new instance has been created.
 * @private
 * @param {Object} options
 */
JamesSearch.prototype.initialize = function (options) {
    this.window = null;
    this.body = null;
    this.form = null;
    this.input = null;
    this.box = null;
    this.query = '';
    this.listeners = [];

    this.xhr = null;

    this.parseOptions(options);
    this.bindFunctions();

    // Start the plugin (deferred)
    setTimeout(this.start, 0);
};

/**
 * Parse options into James own properties.
 * @function JamesSearch#parseOptions
 * @private
 * @param {Object} options
 * @return {this}
 * @throws noDomObject {Error} - DOM Object not found
 */
JamesSearch.prototype.parseOptions = function (options) {
    // Keep a reference to options
    this.options = options || {};

    // Deep clone defaults.
    this.defaults = JSON.parse(JSON.stringify(JamesSearch.defaults));

    this.debug = Utils.parseBoolean(this.options.debug, this.defaults.debug);

    if (options.suggest) {
        // Keep a reference to the dom element
        if (typeof options.suggest.el === 'string') {
            this.el = document.getElementById(options.suggest.el.replace(/#/, ''));
        } else if (options.suggest.el instanceof HTMLElement) {
            this.el = options.suggest.el;
        }

        // Throw an error if no element is found
        if (!this.el) {
            throw (this.ERROR.noDomObject);
        }

        this.form = this.options.suggest.form || this.defaults.suggest.form;
        this.form = document.getElementById(this.form.replace(/#/, ''));
        if (!this.form) {
            throw JamesSearch.ERROR.formNotFound;
        }

        // Extend manually the variables
        this.token = this.options.suggest.token || this.defaults.suggest.token;
        this.url = this.options.suggest.url || this.defaults.suggest.url;
        this.method = (this.options.suggest.method || this.defaults.suggest.method).toUpperCase();
        this.suggestTrackingUrl = this.options.suggest.trackingUrl || this.defaults.suggest.trackingUrl;
        this.limit = this.options.suggest.limit || this.defaults.suggest.limit;
        this.offset = this.options.suggest.offset || this.defaults.suggest.offset;
        this.crop = this.options.suggest.crop || this.defaults.suggest.crop;
        this.minChars = this.options.suggest.minChars || this.defaults.suggest.minChars;
        this.delay = this.options.suggest.delay || this.defaults.suggest.delay;
        this.position = this.options.suggest.position || this.defaults.suggest.position;
        this.margin = this.options.suggest.margin || this.defaults.suggest.margin;
        this.autoWidth = this.options.suggest.autoWidth || this.defaults.suggest.autoWidth;
        this.delimiters = this.options.suggest.delimiters || this.defaults.suggest.delimiters;

        // Parse booleans
        this.keyboard = Utils.parseBoolean(this.options.suggest.keyboard, this.defaults.suggest.keyboard);
        this.horizontalKeyboard = Utils.parseBoolean(this.options.suggest.horizontalKeyboard, this.defaults.suggest.horizontalKeyboard);
        this.blurRemove = Utils.parseBoolean(this.options.suggest.blurRemove, this.defaults.suggest.blurRemove);
        this.inputDebounceDelay = this.options.suggest.inputDebounceDelay || this.defaults.suggest.inputDebounceDelay;

        // Init the template
        this.template = this.initTemplate(this.options.suggest.template || '');

        // Init the target
        this.target = this.initTarget(this.options.suggest.target || null);

        // Init the wrapper template
        this.wrapperTemplate = this.initTemplate(this.options.suggest.wrapperTemplate || this.defaults.suggest.wrapperTemplate);

        // Parse the Array variables
        if (!Array.isArray(this.token)) {
            this.token = this.token.split(',');
        }
        if (!Array.isArray(this.limit)) {
            this.limit = String(this.limit).split(',');
        }
        if (!Array.isArray(this.offset)) {
            this.offset = String(this.offset).split(',');
        }
        if (!Array.isArray(this.crop)) {
            this.crop = String(this.crop).split(',');
        }
        if (this.delimiters && !Array.isArray(this.delimiters)) {
            this.delimiters = this.delimiters.split(',');
        }
    }


    if (options.search) {
        this.searchQuery = this.options.search.query || this.defaults.search.query;
        this.searchSelector = this.options.search.selector || this.defaults.search.selector;
        this.searchTrackingUrl = this.options.search.trackingUrl || this.defaults.search.trackingUrl;
        this.searchMethod = (this.options.search.method || this.defaults.search.method).toUpperCase();
    }

    return this;
};

/**
 * Bind functions.
 * @function JamesSearch#bindFunctions
 * @private
 * @return {this}
 */
JamesSearch.prototype.bindFunctions = function () {
    this.start = Utils.bind(this.start, this);
    this.render = Utils.bind(this.render, this);
    this.onFormChange = Utils.bind(this.onFormChange, this);
    this.onSuccess = Utils.bind(this.onSuccess, this);
    this.onError = Utils.bind(this.onError, this);
    this.suggest = Utils.bind(Utils.debounce(this.suggest, this.delay), this);
    this.onScroll = Utils.bind(this.onScroll, this);
    this.onResize = Utils.bind(this.onResize, this);
    this.onFocus = Utils.bind(this.onFocus, this);
    this.onBlur = Utils.bind(this.onBlur, this);
    this.onMouseEnter = Utils.bind(this.onMouseEnter, this);
    this.onMouseLeave = Utils.bind(this.onMouseLeave, this);
    this.onSuggestLinkClick = Utils.bind(this.onSuggestLinkClick, this);
    this.onKeyup = Utils.bind(this.onKeyup, this);
    this.onKeydown = Utils.bind(this.onKeydown, this);
    this.onReadyStateChange = Utils.bind(this.onReadyStateChange, this);
    this.onSearchLinkClick = Utils.bind(this.onSearchLinkClick, this);

    return this;
};

/**
 * @function JamesSearch#initTemplate
 * @private
 * @param {String} template
 * @param {String} alternative
 * @throws templateNotFound {Error} - Template not found
 */
JamesSearch.prototype.initTemplate = function (template, alternative) {
    // Trim the string.
    template = (template || '').trim();

    // If no template id is defined try an alternative or throw an error.
    if (!template && alternative) {
        return alternative;
    } else if (!template) {
        throw new Error(JamesSearch.ERROR.templateNotFound + ' [' + template + ']');
    }

    // Find the template.
    if (template.charAt(0) === '#') {
        template = template.substr(1);

        if (!document.getElementById(template)) {
            throw new Error(JamesSearch.ERROR.templateNotFound + ' [' + template + ']');
        }

        template = document.getElementById(template).innerHTML;

        // If no template id is defined try an alternative or throw an error.
        if (!template && alternative) {
            return alternative;
        } else if (!template) {
            throw new Error(JamesSearch.ERROR.templateNotFound + ' [' + template + ']');
        }
    } else { // If it is not an ID.
        return template;
    }

    return template;
};

/**
 * @function JamesSearch#initTarget
 * @private
 * @param {String} target
 * @return {HTMLElement}
 */
JamesSearch.prototype.initTarget = function (target) {
    if (target) {
        target = (target).trim();
    }
    let element = document.querySelector(target) || document.body;

    return element;
};

/**
 * Start the plugin
 * @function JamesSearch#start
 * @private
 * @fires JamesSearch#james-search-ready - Triggered on ready
 * @return {this}
 */
JamesSearch.prototype.start = function () {
    if (this.el) {
        this.render();
        this.trigger(JamesSearch.EVENTS.ready);
    }

    if (this.searchSelector) {
        this.enableSearchEvents();
    }

    return this;
};

/**
 * Render the elements
 * @function JamesSearch#render
 * @throws formNotFound {Error} - Form Element not found
 * @throws inputNotFound {Error} - Input text Element not found
 */
JamesSearch.prototype.render = function () {
    // Keep references to DOM elements.
    this.window = window;
    this.body = document.body;

    this.input = this.form.querySelector('.james-search-input');
    if (!this.input) throw JamesSearch.ERROR.inputNotFound;

    // Keep the last value.
    this.query = this.input.value || '';

    // Disable the autocomplete.
    this.input.setAttribute('autocomplete', 'off');

    // Cached template.
    if (this.delimiters) {
        Mustache.parse(this.template, this.delimiters);
        Mustache.parse(this.wrapperTemplate, this.delimiters);
    } else {
        Mustache.parse(this.template);
        Mustache.parse(this.wrapperTemplate);
    }

    this.enableEvents();
    this.resize();
};

/**
 * Enable the associated events.
 * @function JamesSearch#enableEvents
 */
JamesSearch.prototype.enableEvents = function () {
    this.disableEvents();

    if (this.el) {
        this.window.addEventListener('resize', this.onResize);
        this.window.addEventListener('scroll', this.onScroll);

        this.input.addEventListener('focus', this.onFocus);
        this.input.addEventListener('blur', this.onBlur);
        this.input.addEventListener('mouseenter', this.onMouseEnter);
        this.input.addEventListener('mouseleave', this.onMouseLeave);
        this.input.addEventListener('keyup', this.onKeyup);
        this.input.addEventListener('keydown', Utils.debounce(this.onKeydown, this.inputDebounceDelay));
        this.form.addEventListener('input', Utils.debounce(this.onFormChange, this.inputDebounceDelay));
    }
};

/**
 * Disable the associated events.
 * @function JamesSearch#disableEvents
 */
JamesSearch.prototype.disableEvents = function () {
    if (this.el) {
        this.window.removeEventListener('resize', this.onResize);
        this.window.removeEventListener('scroll', this.onScroll);

        this.form.removeEventListener('input', this.onFormChange);

        this.input.removeEventListener('focus', this.onFocus);
        this.input.removeEventListener('blur', this.onBlur);
        this.input.removeEventListener('mouseenter', this.onMouseEnter);
        this.input.removeEventListener('mouseleave', this.onMouseLeave);
        this.input.removeEventListener('keyup', this.onKeyup);
        this.input.removeEventListener('keydown', this.onKeydown);
    }
};

/**
 * Enable the associated search events.
 * @function JamesSearch#enableSearchEvents
 */
JamesSearch.prototype.enableSearchEvents = function () {
    if (this.searchSelector) {
        this.disableSearchEvents();
        Utils.addDocumentEvent('click', this.searchSelector, this.onSearchLinkClick);
    }
};

/**
 * Disable the associated search events.
 * @function JamesSearch#disableSearchEvents
 */
JamesSearch.prototype.disableSearchEvents = function () {
    if (this.searchSelector) {
        Utils.removeDocumentEvent('click', this.onSearchLinkClick);
    }
};

/**
 * Handles the change events in the form data.
 * @function JamesSearch#onFormChange
 * @private
 * @param {Event} e The corresponding event.
 * @fires JamesSearch#james-search-input-change - Triggered on input change
 */
JamesSearch.prototype.onFormChange = function (e) {
    if (e.target !== this.input) return;

    this.trigger(JamesSearch.EVENTS.inputChange, {originalEvent: e});
    this.suggest();
};

/**
 * Start the suggestion process.
 * @function JamesSearch#suggest
 * @fires JamesSearch#james-search-before-send - Triggered before ajax send.
 */
JamesSearch.prototype.suggest = function () {
    var data = {};
    // Abort the previous xhr if exist.
    this.abort();

    // Keep the last value.
    this.query = (this.input.value || '').trim();

    // Check the minmax value of the query.
    if (this.query.length < this.minChars) {
        this.removeBox();

        return;
    }

    data = this.collectSuggestData();
    this.trigger(JamesSearch.EVENTS.beforeSend, data);
    this.submitSuggestRequest(data);
};

/**
 * Submit the suggest request.
 * @function JamesSearch#submitSuggestTracking
 * @param {Object} data The data to be sent.
 */
JamesSearch.prototype.submitSuggestRequest = function (data) {
    data = this.parseFormData(data, this.method);
    this.xhr = this.createXhr(this.method, this.url, data);
    this.xhr.onreadystatechange = this.onReadyStateChange;
    this.sendXhr(this.method, this.xhr, data);
};

/**
 * Create and open a XhrHttpRequest.
 * @function JamesSearch#createXhr
 * @param {String} method The method of the request.
 * @param {String} url The url of the request.
 * @param {Object} data Serialized data array.
 * @return {XMLHttpRequest} Parsed serialized data array.
 */
JamesSearch.prototype.createXhr = function (method, url, data) {
    var xhr = new XMLHttpRequest();

    if (method === 'GET') {
        url += url.indexOf('?') < 0 ? '?' : '&';
        xhr.open(method, url + data, true);
    } else {
        xhr.open(method, url, true);
    }

    return xhr;
};

/**
 * Send a xhr request.
 * @function JamesSearch#sendXhr
 * @param {String} method The request method.
 * @param {XMLHttpRequest} xhr The XMLHttpRequest.
 * @param {Object} data The data to be sent.
 */
JamesSearch.prototype.sendXhr = function (method, xhr, data) {
    if (method === 'GET') {
        xhr.send();
    } else {
        // Send the proper header information along with the request.
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('Connection', 'keep-alive');
        xhr.send(data);
    }
};

/**
 * Prepare data to be sent.
 * @function JamesSearch#collectSuggestData
 * @return {Object} Parsed serialized data array.
 */
JamesSearch.prototype.collectSuggestData = function () {
    var data = Utils.serializeArray(this.form);

    // Add JS values to data.
    data = Utils.addValuesToData(data, 'token', this.token);
    data = this.convertToTokenArray(data, 'limit', this.limit);
    data = this.convertToTokenArray(data, 'offset', this.offset);
    data = this.convertToTokenArray(data, 'crop', this.crop);

    return data;
};

/**
 * Convert a parameter into an associative array (key[token] = value).
 * @function JamesSearch#parseFormData
 * @param {Object} data The data the attribute "key" will be added to.
 * @param {String} key The key of the list.
 * @return {Array} list The list with the values.
 */
JamesSearch.prototype.convertToTokenArray = function (data, key, list) {
    Array.prototype.forEach.call(this.token, (token, i) => {
        var value = '';

        if (i > list.length - 1) {
            value = list[0];
        } else {
            value = list[i];
        }

        // Transform list into an associative array (list[token] = value).
        data = Utils.addValuesToData(data, key + '[' + token + ']', value);
    });

    return data;
};

/**
 * Parse the data of the form.
 * @function JamesSearch#parseFormData
 * @param {Object} data Serialized data array.
 * @param {String} method The method of the request.
 * @return {Object} Parsed serialized data array.
 */
JamesSearch.prototype.parseFormData = function (data) {
    return Utils.parseFormData(data);
};

/**
 * Abort the current suggestion process.
 * @function JamesSearch#abort
 */
JamesSearch.prototype.abort = function () {
    if (this.xhr && this.xhr.abort) {
        this.xhr.abort();
    }

    this.xhr = null;
};

/**
 * On ready state change event handler.
 * @function JamesSearch#onReadyStateChange
 * @private
 * @param {Event} e The corresponding event.
 * @fires JamesSearch#james-search-complete - Triggered on Ajax complete.
 */
JamesSearch.prototype.onReadyStateChange = function () {
    switch (this.xhr.readyState) {
        case 0: // UNINITIALIZED
        case 1: // LOADING
        case 2: // LOADED
        case 3: // INTERACTIVE
            break;
        case 4: // COMPLETED
            this.trigger(JamesSearch.EVENTS.complete, {xhr: this.xhr});

            if (this.xhr.status >= 200 && this.xhr.status < 400) {
                this.onSuccess(this.xhr.responseText);
            } else {
                this.onError(this.xhr);
            }

            break;
    }
};

/**
 * Handles the event 'success'.
 * @function JamesSearch#onSuccess
 * @private
 * @fires JamesSearch#james-search-success - Triggered on Ajax success.
 */
JamesSearch.prototype.onSuccess = function (data) {
    var result, key;

    // Create an object reference to provide manipulation
    if (typeof data === 'string') {
        data = JSON.parse(data);
    } else {
        data = {};
    }

    this.trigger(JamesSearch.EVENTS.success, data);

    // We will parse the objects replacing the token name for alias and adding the token as an attr.
    if (data.result && !Array.isArray(data.result)) {
        result = data.result;
        data.result = {};
        for (key in result) {
            result[key].token = key;
            result[key].more = (result[key].items && result[key].items.length < result[key].total);
            data.result[result[key].alias] = result[key];
        }
    }

    this.addBox();
    this.updateBox(data);
};

/**
 * Handles the event 'error'.
 * @function JamesSearch#onError
 * @private
 * @fires JamesSearch#james-search-error - Triggered on Ajax error.
 */
JamesSearch.prototype.onError = function (xhr) {
    this.trigger(JamesSearch.EVENTS.error, {xhr: xhr});
    this.removeBox();
};

/**
 * Add the autosuggest box to DOM.
 * @function JamesSearch#addBox
 * @fires JamesSearch#james-search-box-before-add - Triggered before the autosuggest box has been added.
 * @fires JamesSearch#james-search-box-added - Triggered after the autosuggest box has been added.
 */
JamesSearch.prototype.addBox = function () {
    var data = {};

    // Check if the box exists
    if (this.box) {
        this.removeBox(true);
    }

    data = {
        html: Mustache.render(this.wrapperTemplate),
    };

    this.trigger(JamesSearch.EVENTS.boxBeforeAdd, data);

    this.box = Utils.textToHTML(data.html);
    this.target.appendChild(this.box);

    if (this.input === document.activeElement) {
        this.activateFocus();
    }

    this.trigger(JamesSearch.EVENTS.boxAdded, {box: this.box});
};

/**
 * Removes the autosuggest box from DOM.
 * @function JamesSearch#removeBox
 * @param {Boolean} force Force remove box (for debug purposes only).
 * @fires JamesSearch#james-search-box-removed - Triggered after the autosuggest box has been removed.
 */
JamesSearch.prototype.removeBox = function (force) {
    // Check if the box exists
    if (!this.box) return;

    // Go away if in debug mode.
    if (this.debug && !force) return;

    // Disable the events on elements within the box
    this.disableBoxEvents();

    // Remove the box
    this.box.parentNode.removeChild(this.box);
    this.box = null;

    this.trigger(JamesSearch.EVENTS.boxRemoved);
};

/**
 * Updates the content of the box.
 * @function JamesSearch#updateBox
 * @param {Object} JSON parsed data.
 * @fires JamesSearch#james-search-box-before-update - Triggered before the autosuggest box has been updated.
 * @fires JamesSearch#james-search-box-updated - Triggered after the autosuggest box has been updated.
 */
JamesSearch.prototype.updateBox = function (data) {
    // Check if the box exists
    if (!this.box) return;

    this.disableBoxEvents();

    // Render the template.
    data = {
        html: (Mustache.render(this.template, data) || '').trim(),
    };

    this.trigger(JamesSearch.EVENTS.boxBeforeUpdate, data);

    this.box.innerHTML = data.html;

    if (data.html === '') {
        Utils.addClass(this.box, 'empty');
    } else {
        Utils.removeClass(this.box, 'empty');
    }

    this.enableBoxEvents();
    this.resize();

    this.trigger(JamesSearch.EVENTS.boxUpdated, {box: this.box});
};

/**
 * Handle the focus event on input.
 * @function JamesSearch#onFocus
 * @private
 * @param {Event} e The corresponding event.
 * @fires JamesSearch#james-search-input-focus - Triggered on focusing the autosuggest input.
 */
JamesSearch.prototype.onFocus = function (e) {
    this.trigger(JamesSearch.EVENTS.inputFocus, {el: e.currentTarget});
    this.activateFocus();
};

/**
 * Handle the blur event on input.
 * @function JamesSearch#onBlur
 * @private
 * @fires JamesSearch#james-search-input-blur - Triggered on blurring the autosuggest input.
 */
JamesSearch.prototype.onBlur = function (e) {
    this.trigger(JamesSearch.EVENTS.inputBlur, {el: e.currentTarget});

    this.input.value = this.query;
    this.deactivateFocus();
};

/**
 * Activate the box by focus.
 * @function JamesSearch#activateFocus
 * @param {Boolean} focus - Gain the focus on input.
 * @fires JamesSearch#james-search-box-shown - Triggered after the autosuggest box has been showed.
 */
JamesSearch.prototype.activateFocus = function (focus) {
    if (!this.box) return;

    Utils.addClass(this.box, 'focus');

    if (focus) {
        this.input.focus();
    }

    this.resize();

    this.trigger(JamesSearch.EVENTS.boxShown, {box: this.box});
};

/**
 * Hide the box by focus.
 * @function JamesSearch#deactivateFocus
 * @fires JamesSearch#james-search-box-hidden - Triggered after the autosuggest box has been hidden.
 */
JamesSearch.prototype.deactivateFocus = function () {
    if (!this.box) return;

    Utils.removeClass(this.box, 'focus');
    this.checkBoxStatus();
    this.trigger(JamesSearch.EVENTS.boxHidden);
};

/**
 * Handle the mouseenter event on mouse.
 * @function JamesSearch#onMouseEnter
 * @private
 * @param {Event} e The corresponding event.
 * @fires JamesSearch#james-search-input-enter - Triggered on entering the autosuggest input.
 */
JamesSearch.prototype.onMouseEnter = function (e) {
    var el = e.currentTarget;
    this.trigger(JamesSearch.EVENTS.inputEnter, {el: el});

    if (Utils.hasClass(el, 'james-search-input') && !Utils.hasClass(this.input, 'focus')) {
        return;
    }

    this.activateMouse();
};

/**
 * Handle the mouseleave event on mouse.
 * @function JamesSearch#onMouseLeave
 * @private
 * @fires JamesSearch#james-search-input-leave - Triggered on leaving the autosuggest input.
 */
JamesSearch.prototype.onMouseLeave = function () {
    this.trigger(JamesSearch.EVENTS.inputLeave);
    this.deactivateMouse();
};

/**
 * Activate the box by mouse.
 * @function JamesSearch#activateMouse
 * @fires JamesSearch#james-search-box-enter - Triggered on entering the autosuggest box.
 */
JamesSearch.prototype.activateMouse = function () {
    if (!this.box) return;

    this.trigger(JamesSearch.EVENTS.boxEnter);
    Utils.addClass(this.box, 'active');
};

/**
 * Hide the box by mouse.
 * @function JamesSearch#deactivateMouse
 * @fires JamesSearch#james-search-box-leave - Triggered on leaving the autosuggest box.
 */
JamesSearch.prototype.deactivateMouse = function () {
    if (!this.box) return;

    if (!this.debug) {
        this.trigger(JamesSearch.EVENTS.boxLeave);
        Utils.removeClass(this.box, 'active');
    }

    this.checkBoxStatus();
};

/**
 * Check if the box shall be removed.
 * @function JamesSearch#checkBoxStatus
 */
JamesSearch.prototype.checkBoxStatus = function () {
    if (!this.box) return;

    if (Utils.hasClass(this.box, 'active') || Utils.hasClass(this.box, 'focus')) {
        this.enableBoxEvents();
        return true;
    } else if (this.blurRemove) {
        this.removeBox();
        return false;
    }
};

/**
 * Add the events within the box.
 * @function JamesSearch#enableBoxEvents
 * @private
 */
JamesSearch.prototype.enableBoxEvents = function () {
    var $links,
        i;
    this.disableBoxEvents();

    this.box.addEventListener('mouseenter', this.onMouseEnter);
    this.box.addEventListener('mouseleave', this.onMouseLeave);

    $links = this.box.querySelectorAll('.link');
    for (i = $links.length - 1; i >= 0; i--) {
        $links[i].addEventListener('click', this.onSuggestLinkClick);
    }
};

/**
 * Remove the events within the box.
 * @function JamesSearch#disableBoxEvents
 * @private
 */
JamesSearch.prototype.disableBoxEvents = function () {
    var $links,
        i;

    this.box.removeEventListener('mouseenter', this.onMouseEnter);
    this.box.removeEventListener('mouseleave', this.onMouseLeave);

    $links = this.box.querySelectorAll('.link');
    for (i = $links.length - 1; i >= 0; i--) {
        $links[i].removeEventListener('click', this.onSuggestLinkClick);
    }
};

/**
 * Handles the event ´click´ on .links elements.
 * @function JamesSearch#onSuggestLinkClick
 * @private
 */
JamesSearch.prototype.onSuggestLinkClick = function (e) {
    var el = e.currentTarget,
        query = (el.getAttribute('data-query') || '').trim();

    if (this.debug) {
        e.preventDefault();
    }

    if (Utils.hasClass(el, 'james-search-autosuggest-link') && query) {
        if (e && e.preventDefault) e.preventDefault();
        if (e && e.stopImmediatePropagation) e.stopImmediatePropagation();

        this.updateInput(query, true);
    } else if (Utils.hasClass(el, 'james-search-autosuggest') && query) {
        if (e && e.preventDefault) e.preventDefault();
        if (e && e.stopImmediatePropagation) e.stopImmediatePropagation();

        this.updateInput(query);
    }

    this.trackSuggestClick(e);
};

/**
 * Updates the input element.
 * @function JamesSearch#updateInput
 * @param {String} query The input value will be updated with this String.
 * @param {Boolean} submit Submit the form.
 */
JamesSearch.prototype.updateInput = function (query, submit) {
    // Sanitize the string
    query = Utils.htmlToText(query);

    // Update the text
    this.input.value = query;

    // Get the focus
    this.input.focus();

    if (submit) {
        // Submit the form
        this.form.submit();
    } else {
        // Reload the box
        this.suggest();
    }
};

/**
 * Check if suggestTrackingUrl is defined and then track the click on suggest link.
 * @function JamesSearch#trackSuggestClick
 * @param {Event} e The corresponding event.
 * @fires JamesSearch#james-search-suggest-tracking-before-send - Triggered before suggest tracking send.
 */
JamesSearch.prototype.trackSuggestClick = function (e) {
    var el,
        id,
        closestAlias,
        token,
        data;

    if (!this.suggestTrackingUrl) {
        return;
    }

    el = e.currentTarget;
    id = Utils.htmlToText(el.getAttribute('data-id'));
    closestAlias = Utils.closest(el, '[data-alias]');
    token = '';
    data = null;

    if (closestAlias) {
        token = closestAlias.getAttribute('data-token');
    }

    if (token !== '') {
        data = [];
        data = Utils.addValuesToData(data, 'token', token);
        data = Utils.addValuesToData(data, 'query', this.query);
        data = Utils.addValuesToData(data, 'id', id);

        this.trigger(JamesSearch.EVENTS.suggestTrackingBeforeSend, data);
        this.submitSuggestTracking(data);
    }
};

/**
 * Send a request to track the suggest.
 * @function JamesSearch#submitSuggestTracking
 * @private
 * @param {Object} data to be sent.
 */
JamesSearch.prototype.submitSuggestTracking = function (data) {
    var xhr;
    data = this.parseFormData(data, this.method);
    xhr = this.createXhr(this.method, this.suggestTrackingUrl, data);
    this.sendXhr(this.method, xhr, data);
};

/**
 * Handles the event ´keyup´ on input.
 * @function JamesSearch#onKeyup
 * @private
 * @param {Event} e The corresponding event.
 */
JamesSearch.prototype.onKeyup = function (e) {
    var code;
    if (!this.keyboard || !this.box) return;

    code = e.keyCode;

    switch (code) {
        case 27: // escape
        case 38: // arrow up
        case 40: // arrow down
            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopImmediatePropagation) e.stopImmediatePropagation();

            this.useKeycode(code);
            break;
        case 37: // arrow left
        case 39: // arrow right
            if (this.horizontalKeyboard) {
                if (e && e.preventDefault) e.preventDefault();
                if (e && e.stopImmediatePropagation) e.stopImmediatePropagation();

                this.useKeycode(code);
            }
            break;
    }
};

/**
 * Apply the given keycode.
 * @function JamesSearch#useKeycode
 * @param {Integer} code The key code.
 */
JamesSearch.prototype.useKeycode = function (code) {
    if (!this.box) return;

    switch (code) {
        case 27: // escape
            this.input.value = this.query;
            if (this.blurRemove) {
                this.removeBox();
            } else {
                this.deactivateFocus();
            }
            break;
        case 38: // arrow up
            this.highlightLink(-1);
            break;
        case 40: // arrow down
            this.highlightLink(1);
            break;
        case 13: // enter
            this.highlightLink(1);
            break;
        case 37: // arrow left
            this.highlightLink(-1, true);
            break;
        case 39: // arrow right
            this.highlightLink(1, true);
            break;
    }
};

/**
 * Highlight an element within the box.
 * @function JamesSearch#highlightLink
 * @private
 * @param {Integer} direction The direction to navigate (-1: up / left; 1:down / right).
 * @param {Boolean} horizontal Horizontal navigation.
 */
JamesSearch.prototype.highlightLink = function (direction, horizontal) {
    var links,
        link;
    if (!this.box) return;

    links = Array.from(this.box.querySelectorAll('.link'));
    link = this.findLinkToHighlight(direction, horizontal, links);

    this.highlightSelectedLink(link, links);
    this.scrollToSelectedLink(link);
    this.updateInputBySuggestLink(link);
};

/**
 * Highlight the selected link and remove highlight from other ones.
 * @function JamesSearch#findLinkToHighlight
 * @private
 * @param {Integer} direction The direction to navigate (-1: up / left; 1:down / right).
 * @param {Boolean} horizontal Horizontal navigation.
 * @param {Array} links Array with the links in the suggestBox.
 */
JamesSearch.prototype.findLinkToHighlight = function (direction, horizontal, links) {
    var i, j,
        selected = this.box.querySelector('.link.selected'),
        index = Array.prototype.indexOf.call(links, selected),
        currentGroup = selected ? selected.getAttribute('data-group') : null,
        found = false;

    // If horizontal / group move is required.
    if (selected && horizontal) {
        // Loop for finding the next/previous group.
        for (i = 1; i < links.length && !found; i++) {
            j = (index + ((direction < 0 ? -1 : 1) * i));

            while (j < 0) j += links.length;
            while (j >= links.length) j -= links.length;

            // If the group are differents, we search for the element.
            if (links[j].getAttribute('data-group') && links[j].getAttribute('data-group') !== currentGroup) {
                found = true;

                // Find the first sibling object if arrow left was pressed.
                if (direction < 0) {
                    while (j - 1 >= 0 && links[j - 1].getAttribute('data-group') && links[j].getAttribute('data-group') === links[j - 1].getAttribute('data-group')) {
                        j--;
                    }
                }
            }
        }

        index = j;
    } else {
        index += direction;
    }

    if (index < 0) {
        index = links.length - 1;
    } else if (index > links.length - 1) {
        index = 0;
    }

    return links[index];
};

/**
 * Highlight the selected link and remove highlight from other ones.
 * @function JamesSearch#highlightSelectedLink
 * @param {DOMElement} link The link to highlight.
 * @param {Array} links List with links to remove the highlight.
 */
JamesSearch.prototype.highlightSelectedLink = function (link, links) {
    var i;
    for (i = 0; i < links.length; i++) {
        Utils.removeClass(links[i], 'selected');
    }

    Utils.addClass(link, 'selected');
};

/**
 * Scroll to the selected link.
 * @function JamesSearch#scrollToLink
 * @private
 * @param {DOMElement} link The selected link.
 */
JamesSearch.prototype.scrollToSelectedLink = function (link) {
    // Scroll if necessary.
    var offsetBox = Utils.offset(this.box);
    var offsetLink = Utils.offset(link);

    var position = {
        top: offsetLink.top - offsetBox.top,
        left: offsetLink.left - offsetBox.left,
    };

    var boxSize = {
        width: this.box.offsetWidth,
        height: this.box.offsetHeight,
    };

    var linkSize = {
        width: link.offsetWidth,
        height: link.offsetHeight,
    };

    if (position.top < 0) {
        this.box.scrollTop += position.top;
    } else if (position.top >= boxSize.height) {
        this.box.scrollTop += position.top - boxSize.height + linkSize.height;
    }

    if (position.left < 0) {
        this.box.scrollLeft += position.left;
    } else if (position.left > boxSize.width) {
        this.box.scrollLeft += position.left - boxSize.width + linkSize.width;
    }
};

/**
 * Update the input field based on content of the suggest link.
 * @function JamesSearch#updateInputBySuggestLink
 * @param {DOMElement} link The selected link.
 */
JamesSearch.prototype.updateInputBySuggestLink = function (link) {
    var text = '';

    // Check if the element is an autosuggest.
    if (Utils.hasClass(link, 'james-search-autosuggest-link')) {
        // Sanitize the string.
        text = Utils.htmlToText(link.getAttribute('data-query'));

        if (text) {
            this.input.value = text;
        } else {
            this.input.value = this.query;
        }
    } else {
        this.input.value = this.query;
    }
};

/**
 * Handles the event ´onKeydown´ on input forn canceling the ENTER key when an autossugest link is selected.
 * @function JamesSearch#onKeydown
 * @private
 * @param {Event} e The corresponding event.
 */
JamesSearch.prototype.onKeydown = function (e) {
    var $link,
        query;

    // Check if the box and the keyboard are activated.
    if (e.keyCode === 13 && this.keyboard && this.box) {
        // Search for a selected autosuggest link.
        $link = this.box.querySelector('.link.selected');
        query = ($link.getAttribute('data-query') || '').trim();

        if (Utils.hasClass($link, 'james-search-autosuggest')) {
            // If success, cancel the event on ENTER.
            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopImmediatePropagation) e.stopImmediatePropagation();

            this.updateInput(query);

            return false;
        } else if (Utils.hasClass($link, 'james-search-autosuggest-link') && query) {
            this.updateInput(query, true);
        } else {
            // Cancel the event on ENTER and follow the link.
            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopImmediatePropagation) e.stopImmediatePropagation();

            $link.click();

            return false;
        }
    }
};

/**
 * Handles the event ´scroll´.
 * @function JamesSearch#onScroll
 * @private
 */
JamesSearch.prototype.onScroll = function () {
    this.resize();
};

/**
 * Handles the event ´resize´.
 * @function JamesSearch#onResize
 * @private
 */
JamesSearch.prototype.onResize = function () {
    this.resize();
};

/**
 * Resize and adjust the position.
 * @function JamesSearch#resize
 * @fires JamesSearch#james-search-box-before-resize - Triggered after a window resize event and before new styles be applied to the autosuggest box.
 * @fires JamesSearch#james-search-box-resized - Triggered after a window resize event and after new styles has been applied to the autosuggest box.
 */
JamesSearch.prototype.resize = function () {
    var style,
        offset,
        screen,
        position,
        box,
        computed,
        key,
        properties;

    if (!this.box) return;
    if (this.target !== this.body) return;

    // Reset the CSS of the box.
    style = this.box.style;

    style.left = '';
    style.top = '';
    style.width = '';
    style.minWidth = '';
    style.maxWidth = '';

    // Get the offset of the input.
    offset = Utils.offset(this.input);

    // Get the scroll and size of the window.
    screen = {
        top: window.scrollY || document.documentElement.scrollTop,
        left: window.scrollX || document.documentElement.scrollLeft,
        width: this.body.offsetWidth,
        height: this.body.offsetHeight,
    };

    // Compute the absolute position and the width/height of the input.
    position = {
        top: offset.top - screen.top,
        left: offset.left - screen.left,
        width: this.input.offsetWidth,
        height: this.input.offsetHeight,
    };

    // Get the size of the box.
    box = {
        width: this.box.offsetWidth,
        height: this.box.offsetHeight,
    };

    // Compute the values.
    computed = {
        top: position.top + position.height,
        minWidth: position.width,
    };

    if (box.width < position.width) {
        box.width = position.width;
    }

    // Force width.
    if (this.autoWidth === 'fixed') {
        box.width = position.width;
        computed.width = position.width;
    }

    // Set the position.
    if (this.position === 'left') {
        computed.left = position.left;
    } else {
        computed.left = position.left + position.width - box.width;
    }

    // Check the final size of the box.
    if (this.position === 'left' && (computed.left + box.width > screen.width - this.margin)) {
        computed.maxWidth = screen.width - computed.left - this.margin;
    } else if (this.position !== 'left' && computed.left < this.margin) {
        computed.left = this.margin;
        computed.maxWidth = position.left + position.width - computed.left;
    }

    // Force width.
    if (this.autoWidth === 'fixed') {
        computed.minWidth = position.width;
        computed.maxWidth = position.width;
    }

    key = null;
    properties = ['left', 'top', 'width', 'minWidth', 'maxWidth'];

    for (key in computed) {
        if (!isNaN(computed[key]) && computed[key] > 0 && properties.indexOf(key) !== -1) {
            computed[key] = computed[key] + 'px';
        }
    }

    this.trigger(JamesSearch.EVENTS.boxBeforeResize, { style: computed });

    // Apply the values.
    style = this.box.style;

    for (key in computed) {
        style[key] = computed[key];
    }

    this.trigger(JamesSearch.EVENTS.boxResized, { style: style });
};


/**
 * Handles the event ´click´ on search links.
 * @function JamesSearch#onSearchLinkClick
 * @private
 */
JamesSearch.prototype.onSearchLinkClick = function (e) {
    this.trackSearchClick(e);
};

/**
 * Check if searchTrackingUrl is defined and then track the click on suggest link.
 * @function JamesSearch#trackSearchClick
 * @param {Event} e The corresponding event.
 * @fires JamesSearch#james-search-search-tracking-before-send - Triggered before search tracking send.
 */
JamesSearch.prototype.trackSearchClick = function (e) {
    var el,
        id,
        closestId,
        closestAlias,
        token,
        query,
        data;

    if (this.debug) {
        e.preventDefault();
    }

    if (!this.searchTrackingUrl) {
        return;
    }

    el = e.target;
    id = Utils.htmlToText(el.getAttribute('data-id'));
    closestId = '';
    closestAlias = Utils.closest(el, '[data-alias]');
    token = '';
    query = this.searchQuery;
    data = null;

    if (!id) {
        closestId = Utils.closest(el, '[data-id]');
        if (closestId) {
            id = closestId.getAttribute('data-id');
        }
    }

    if (closestAlias) {
        token = closestAlias.getAttribute('data-token');
    }

    if (token && query && id) {
        data = [];
        data = Utils.addValuesToData(data, 'token', token);
        data = Utils.addValuesToData(data, 'query', query);
        data = Utils.addValuesToData(data, 'id', id);

        this.trigger(JamesSearch.EVENTS.searchTrackingBeforeSend, data);
        this.submitSearchTracking(data);
    }
};

/**
 * Send a request to track the search.
 * @function JamesSearch#submitSearchTracking
 * @param {Object} data to be sent.
 */
JamesSearch.prototype.submitSearchTracking = function (data) {
    var xhr;

    data = this.parseFormData(data, this.searchMethod);
    xhr = this.createXhr(this.searchMethod, this.searchTrackingUrl, data);
    this.sendXhr(this.searchMethod, xhr, data);
};

/**
 * Add an event listener.
 * @function JamesSearch#on
 * @alias JamesSearch#addEventListener
 * @param {String} name ID of the event to be listened.
 * @param {Function} [callback] Callback function.
 */
JamesSearch.prototype.on = JamesSearch.prototype.addEventListener = function (name, callback) {
    Utils.on(this, name, callback);
};

/**
 * Remove an event listener.
 * @function JamesSearch#off
 * @alias JamesSearch#removeEventListener
 * @param {String} name ID of the event to be listened.
 * @param {Function} [callback] Callback function.
 */
JamesSearch.prototype.off = JamesSearch.prototype.removeEventListener = function (name, callback) {
    Utils.off(this, name, callback);
};

/**
 * Trigger an event to current listeners and trigger it also to the DOM:
 * @function JamesSearch#trigger
 * @param {String} name ID of the event to be triggered.
 * @param {Object[]} [data] Attached data.
 */
JamesSearch.prototype.trigger = function (name, data) {
    Utils.trigger(this, name, data);
};

/**
 * Destroys the instance. Use ´delete myJamesSearch.destroy();´;
 * @function JamesSearch#destroy
 * @return {this}
 */
JamesSearch.prototype.destroy = function () {
    this.removeBox(true);
    this.disableEvents();
    this.disableSearchEvents();
    Utils.off(this.listeners);

    return this;
};

window.JamesSearch = JamesSearch;
export default JamesSearch;
